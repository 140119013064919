import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "appState",
  initialState: {
    isDisconnecting: false,
    isOnboarding: true,
    isPlaying: false,
    isConnected: false,
    isHelpOpen: false,
    isSearching: false,
    isPlayerOpen: true,
    selectedCategory: null,
    totalCategories: 0,
    featuredCategories: [], // browsing
    categories: [], // browsing / search result
    pillars: [],
    listTitle: "",
    isSessionEnded: false,
    socketSessionKey: null,
    wallId: 1,
  },
  reducers: {
    closeOnboarding: (state) => {
      state.isOnboarding = false;
      state.isConnected = true;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTotalCategories: (state, action) => {
      state.totalCategories = action.payload;
    },
    setPillars: (state, action) => {
      state.pillars = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = {
        id: action.payload._id,
        pillar: action.payload.pillar,
        title: action.payload.title,
        stories: action.payload.stories,
      };
    },
    setFeaturedCategories: (state, action) => {
      state.featuredCategories = action.payload;
    },
    closePlayer: (state) => {
      state.isPlayerOpen = false;
    },
    openPlayer: (state) => {
      state.isPlayerOpen = true;
    },
    setIsSearching: (state) => {
      state.isSearching = true;
    },
    unsetIsSearching: (state) => {
      state.isSearching = false;
    },
    setListTitle: (state, action) => {
      state.listTitle = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    openHelp: (state) => {
      state.isHelpOpen = true;
    },
    closeHelp: (state) => {
      state.isHelpOpen = false;
    },
    endSession: (state) => {
      state.isSessionEnded = true;
    },
    setIsDisconnecting: (state) => {
      state.isDisconnecting = true;
    },
    unsetIsDisconnecting: (state) => {
      state.isDisconnecting = false;
    },
    setSocketSessionKey: (state, action) => {
      state.socketSessionKey = action.payload;
    },
    setWallId: (state, action) => {
      state.wallId = action.payload;
    },
  },
});

export const {
  closeOnboarding,
  setPillars,
  closePlayer,
  openPlayer,
  setFeaturedCategories,
  setTotalCategories,
  setSelectedCategory,
  setCategories,
  setIsSearching,
  unsetIsSearching,
  setListTitle,
  setIsPlaying,
  openHelp,
  closeHelp,
  endSession,
  setIsDisconnecting,
  unsetIsDisconnecting,
  setSocketSessionKey,
  setWallId,
} = appSlice.actions;

export default appSlice.reducer;
