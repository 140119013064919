import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material";

import { store } from "./services/store";

import App from "./App";

import "./index.scss";

export const themeApp = createTheme({
  typography: {
    fontFamily: [
      "Nunito",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
    ].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#5e66a5",
    },
    secondary: {
      main: "#f50057",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: `50px`,
          fontSize: `16px`,
          fontWeight: 700,
          letterSpacing: `0.05em`,
        },
      },
    },
  },
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={themeApp}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);
