import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import socket from "./services/socket";

import { TimerHelperTooltip } from "./const";

import { useGetWallQuery } from "./services/api/wall";
import {
  setPillars,
  setFeaturedCategories,
  setTotalCategories,
  setSocketSessionKey,
  setWallId,
} from "./services/reducer";

import OnBoarding from "./components/OnBoarding";
import Main from "./components/Main";
import SessionEnded from "./components/SessionEnded";
import HelperTooltip from "./components/HelperTooltip";

window.inactiveTime = 0;

function App() {
  // ?wallId=[1,2,3]&sessionId=[randomgeneratedstring]
  const [isHelperTooltipOpen, setIsHelperTooltipOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const wallData = useGetWallQuery();

  const handleInactive = () => {
    setIsHelperTooltipOpen(true);
  };

  const handleHelperTooltipClose = () => {
    setIsHelperTooltipOpen(false);
  };

  const handleTimer = () => {
    if (!window.isPlaying) {
      window.inactiveTime++;
      if (window.inactiveTime === TimerHelperTooltip) {
        handleInactive();
      }
    }
  };

  const startTimer = () => {
    setInterval(handleTimer, 1000);
  };

  const resetTimer = () => {
    window.inactiveTime = 0;
  };

  window.ontouchstart = resetTimer;

  useEffect(() => {
    startTimer();
    socket.on("connect", () => {});
    dispatch(setWallId(searchParams.get("wallId")));
    dispatch(setSocketSessionKey(searchParams.get("sessionId")));
  }, []);

  useEffect(() => {
    if (appState.socketSessionKey) {
      socket.emit("userConnect", { sessionKey: appState.socketSessionKey });
    }
  }, [appState.socketSessionKey]);

  useEffect(() => {
    window.inactiveTime = 0;
  }, [appState.isPlaying]);

  useEffect(() => {
    if (wallData.isSuccess) {
      let pillars = {};
      let totalCategories = 0;
      wallData.data.data.pillars.map((pillar) => {
        let categories = pillar.categories.map((category) => {
          const stories = category.stories.map((story) => {
            if (story.is_wall_enabled) {
              return { ...story };
            }
          });
          return { ...category, pillar: pillar.heading, stories: stories };
        });
        pillars[pillar.key] = {
          heading: pillar.heading,
          subheading: pillar.subheading,
          categories: categories,
        };
        totalCategories += pillar.categories.length;
      });
      dispatch(setPillars(pillars));
      dispatch(setFeaturedCategories(wallData.data.data.pillars[0].categories)); // set featured temporarily
      dispatch(setTotalCategories(totalCategories));
    }
  }, [wallData]);

  if (appState.isOnboarding) return <OnBoarding />;

  return (
    <>
      <Main />
      <SessionEnded />
      <HelperTooltip
        isOpen={isHelperTooltipOpen}
        onClose={handleHelperTooltipClose}
      />
    </>
  );
}

export default React.memo(App);
