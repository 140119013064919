import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { useGetWallQuery } from "../../services/api/wall";
import { closeOnboarding } from "../../services/reducer";

import ImgLogo from "../../assets/images/logo-colour.svg";
import ImgConnecting from "../../assets/images/home-connecting.svg";

import "./onboarding.scss";

const OnBoarding = React.memo(() => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const wallData = useGetWallQuery();

  const [currentSlide, setCurrentSlide] = useState(1);

  const handleOnClick = () => {
    setCurrentSlide(currentSlide + 1);
    let de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen();
    } else if (de.webkitRequestFullscreen) {
      de.webkitRequestFullscreen();
    } else if (de.msRequestFullscreen) {
      de.msRequestFullscreen();
    }

    window.screen.orientation.lock("portrait");
  };

  useEffect(() => {
    if (currentSlide === 3) {
      setTimeout(() => {
        setCurrentSlide(4);
      }, 2000);
    }
    if (currentSlide === 4) {
      setTimeout(() => {
        dispatch(closeOnboarding());
      }, 1000);
    }
  }, [currentSlide]);

  return (
    <div id="Onboarding" className={`${currentSlide > 2 && "connecting"}`}>
      <div className="logo">
        <img src={ImgLogo} />
      </div>
      <div
        className={`slide slide-1 ${currentSlide === 1 && "onShow"} ${
          currentSlide !== 1 && "onHide"
        }`}
      >
        <h1>
          <small>Welcome to the</small> <br />
          Celestial Wall <br />
          Experience
        </h1>
      </div>
      <div
        className={`slide slide-2 ${currentSlide === 1 && "onReady"} ${
          currentSlide === 2 && "onShow"
        }`}
      >
        <p>
          Let's connect your mobile phone to <b>remote control</b> the wall and
          browse our stories
        </p>
        <img src={ImgConnecting} width="100%" />
      </div>
      <Button
        variant="contained"
        className="btn-bottom"
        onClick={handleOnClick}
      >
        {currentSlide === 1 ? `Next` : `Connect`}
      </Button>
      <div className={`dots ${currentSlide === 2 && "slide-2"}`}></div>
      <div className="slide-connecting">
        {wallData.isSuccess && currentSlide === 4 ? (
          <div className="icon-connected">
            <CheckIcon />
          </div>
        ) : (
          <CircularProgress size={90} thickness={1.2} />
        )}
        <p>
          <b>
            {wallData.isSuccess && currentSlide === 4 ? (
              "CONNECTED"
            ) : (
              <>
                CONNECTING TO <br />
                CELESTIAL WALL
              </>
            )}
          </b>
        </p>
      </div>
    </div>
  );
});

export default OnBoarding;
