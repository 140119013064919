import React, { useState } from "react";

import { Button } from "@mui/material";

import "./helper-tooltip.scss";

export default function HelperTooltip({ isOpen, onClose }) {
  return (
    <div id="HelperTooltip" className={`${isOpen ? "open" : "close"}`}>
      <div className="tooltip">
        <p>Use the buttons to explore different parts of the story</p>
        <Button onClick={onClose}>GOT IT</Button>
      </div>
    </div>
  );
}

//   <HelperTooltip isOpen={isHelperTooltipOpen} onClose={handleHelperTooltipClose} />
//

// Use the buttons to explore different parts of the story
