import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { api } from "../api";
import appReducer from "../reducer";

export const createStore = (options) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      appState: appReducer,
    },
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

export const store = createStore();

export const useAppDispatch = useDispatch;
export const useTypedSelector = useSelector;
