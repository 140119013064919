import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { openHelp, setIsDisconnecting } from "../../services/reducer";

import IconWall from "../../assets/images/icon-wall-3.svg";

import "./header.scss";

export default function Header() {
  const dispatch = useDispatch();

  const handleOpenHelp = () => {
    dispatch(openHelp());
  };

  const handleDisconnect = () => {
    dispatch(setIsDisconnecting());
  };

  return (
    <div id="Header">
      <div className="btn-disconnect">
        <IconButton aria-label="Disconnect" onClick={handleDisconnect}>
          <PowerSettingsNewIcon />
        </IconButton>
      </div>
      <div className="text-status">
        <Typography variant="h5">
          Celestial Wall
          <br />
          <span>CONNECTED</span>
        </Typography>
      </div>
      <div className="icon-wall">
        <img src={IconWall} />
      </div>
      <div className="btn-help">
        <IconButton aria-label="Help" onClick={handleOpenHelp}>
          <QuestionMarkIcon />
        </IconButton>
      </div>
    </div>
  );
}
