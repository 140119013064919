import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, IconButton, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { unsetIsDisconnecting, endSession } from "../../services/reducer";

import IconDisconnect from "../../assets/images/icon-disconnect.svg";

import "./disconnect.scss";

export default function Disconnect() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleCancel = () => {
    dispatch(unsetIsDisconnecting());
  };

  const handleLeave = () => {
    dispatch(endSession());
  };

  return (
    <div
      id="Disconnect"
      className={`${appState.isDisconnecting ? "open" : "close"}`}
    >
      <div className="wrapper">
        <img src={IconDisconnect} />
        <p>
          Are you sure you want to disconnect from the wall and leave the Peter
          Mac Experience?
        </p>
        <div className="buttons">
          <Button onClick={handleLeave} className="btn-leave">
            Leave
          </Button>
          <Button onClick={handleCancel} className="btn-cancel">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
