import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { closePlayer, openPlayer, setIsPlaying } from "../../services/reducer";
import socket from "../../services/socket";
import mainScrollTop from "../../services/util";

import { DonationLink } from "../../const";

import IconDonate from "../../assets/images/icon-donate-heart.svg";
import IconBrowse from "../../assets/images/icon-browse.svg";
import IconNext from "../../assets/images/icon-navigation-left.svg";
import IconPrevious from "../../assets/images/icon-navigation-right.svg";
import IconRemote from "../../assets/images/icon-remote.svg";

import "./player.scss";

let storyCountdown = 0;
let StoryCountdownTimer;
let isPaused = 0;
const defaultPlaytime = 120;

export default function Player() {
  const sliderElement = useRef();
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [playerProgress, setPlayerProgress] = useState(0);
  const [storyPlayTime, setStoryPlayTime] = useState(0);

  const [playerTimer, setPlayerTimer] = useState(0);

  const isCategorySelected = appState.selectedCategory !== null;

  const handleClosePlayer = () => {
    dispatch(closePlayer());
    mainScrollTop();
  };

  const handleOpenPlayer = () => {
    dispatch(openPlayer());
  };

  const BtnBrowse = () => {
    return (
      <Button className="btn-browse" onClick={handleClosePlayer}>
        <img src={IconBrowse} alt="Browse" /> Browse
      </Button>
    );
  };

  const handleNext = () => {
    document.querySelector(".slick-next").click();
    socket.emit("nextPage", {
      sessionKey: appState.socketSessionKey,
    });
  };
  const handlePrev = () => {
    document.querySelector(".slick-prev").click();
    socket.emit("prevPage", {
      sessionKey: appState.socketSessionKey,
    });
  };

  const Navigation = () => {
    return (
      <div className="navigation">
        <BtnBrowse />
        <Button className="btn-previous" onClick={handlePrev}>
          <img src={IconPrevious} alt="Previous" />
        </Button>
        <Button className="btn-next" onClick={handleNext}>
          <img src={IconNext} alt="Next" />
        </Button>
      </div>
    );
  };

  const slickConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  const onAfterChange = (current) => {
    setCurrentSlide(current);
    handlePlay(appState.selectedCategory.stories[current]);
  };

  function IconVideo() {
    return (
      <svg viewBox="0 0 26 21" xmlns="http://www.w3.org/2000/svg" fill="none">
        <rect
          x="1.39775"
          y="0.965128"
          width="22.7295"
          height="18.9412"
          rx="4.25953"
          strokeWidth="1.89412"
        />
        <path d="M17.9713 9.61569C18.6026 9.98021 18.6026 10.8915 17.9713 11.256L9.4477 16.1771C8.81633 16.5416 8.02711 16.086 8.02711 15.3569L8.02711 5.51479C8.02711 4.78574 8.81633 4.33009 9.4477 4.69461L17.9713 9.61569Z" />
      </svg>
    );
  }

  const handlePlay = (story) => {
    const playTime = defaultPlaytime;
    setStoryPlayTime(playTime); // story.viewtimeinseconds in seconds
    clearTimeout(playerTimer);
    setPlayerTimer(setTimeout(handleStoryEnd, playTime * 1000));
    clearTimeout(StoryCountdownTimer);

    isPaused = 0;
    storyCountdown = playTime;
    StoryCountdownTimer = setInterval(handleStoryCountdown, 1000);

    window.isPlaying = 1;

    setPlayerProgress(0);
    dispatch(setIsPlaying(story._id));

    socket.emit("playVideo", {
      sessionKey: appState.socketSessionKey,
    });
  };

  const handleClickStory = (story, index) => {
    if (index === currentSlide) {
      if (window.isPlaying !== 1) {
        if (isPaused === 0) {
          handlePlay(story);
        } else {
          // resume
          setPlayerProgress(100);
          setStoryPlayTime(storyCountdown);
          setPlayerTimer(setTimeout(handleStoryEnd, storyCountdown * 1000));
          StoryCountdownTimer = setInterval(handleStoryCountdown, 1000);
          window.isPlaying = 1;
          isPaused = 0;
          socket.emit("playVideo", {
            sessionKey: appState.socketSessionKey,
          });
        }
      } else {
        handleStoryPause();
      }
    }
  };

  const handleStoryCountdown = () => {
    storyCountdown--;
  };

  const handleStoryEnd = () => {
    dispatch(setIsPlaying(false));
    setPlayerProgress(0);
    clearTimeout(StoryCountdownTimer);
    window.isPlaying = 0;
  };

  const handleStoryPause = () => {
    const originalPlayTime = defaultPlaytime; // appState.selectedCategory.stories[currentSlide].viewtimeinseconds;
    const playedPercentage =
      ((originalPlayTime - storyCountdown) / originalPlayTime) * 100;
    setPlayerProgress(playedPercentage);
    clearTimeout(playerTimer);
    clearTimeout(StoryCountdownTimer);
    window.isPlaying = 0;
    isPaused = 1;
    socket.emit("pauseVideo", {
      sessionKey: appState.socketSessionKey,
    });
  };

  useEffect(() => {
    setPlayerProgress(100);
  }, [appState.isPlaying]);

  return (
    <div
      id="Player"
      className={`${appState.isPlayerOpen ? "open" : "close"} ${
        isCategorySelected && "view-category"
      }`}
    >
      {isCategorySelected ? (
        <div className="category">
          <h2>{appState.selectedCategory.title}</h2>

          <Slider
            {...slickConfig}
            id="PlayerSlider"
            afterChange={onAfterChange}
            ref={sliderElement}
          >
            {appState.selectedCategory.stories.map((story, index) => {
              return (
                <div className="story-wrapper" key={`Player-Stories-${index}`}>
                  {story._id === appState.isPlaying && (
                    <CircularProgressbar
                      value={playerProgress}
                      id="CircularProgressbar"
                      styles={buildStyles({
                        pathTransitionDuration: storyPlayTime,
                      })}
                    />
                  )}
                  <div
                    className="story-item"
                    onClick={() => handleClickStory(story, index)}
                  >
                    <h3
                      style={{
                        fontSize: `${
                          story.name.length > 60
                            ? "4vw"
                            : story.name.length > 37
                            ? "4.9vw"
                            : "5.6vw"
                        }`,
                      }}
                    >
                      {story.name}
                    </h3>
                    <div>
                      <p>2 mins</p>
                      <div className="icon">
                        <IconVideo />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {appState.isPlaying && (
            <div className="casting-message">Casting on Celestial Wall</div>
          )}
        </div>
      ) : (
        <div className="intro">
          <BtnBrowse />
          <p className="text-highlight">
            Choose a story
            <br /> to view on the wall
          </p>
        </div>
      )}
      <Navigation />
      <div className="btn-donate-wrapper">
        <Button className="btn-donate" href={DonationLink} target="_blank">
          <img src={IconDonate} alt="Donate" />
          DONATE
          <br />
          NOW
        </Button>
      </div>
      {isCategorySelected ? (
        <></>
      ) : (
        <div className="loading">
          <div className="title"></div>

          <div className="stories"></div>
        </div>
      )}
      <div className="mini" onClick={handleOpenPlayer}>
        <div className="icon-remote">
          <img src={IconRemote} alt="Remote" />
        </div>
        {isCategorySelected ? (
          <div className="current-category">
            <h3>{appState.selectedCategory.title}</h3>
            <p>
              {appState.selectedCategory.pillar} story{" "}
              <span>
                {currentSlide + 1}/{appState.selectedCategory.stories.length}
              </span>
            </p>
            {appState.isPlaying && (
              <CircularProgress size={20} thickness={6} className="progress" />
            )}
          </div>
        ) : (
          <p>Select a story</p>
        )}
      </div>
    </div>
  );
}
