import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { useGetWallQuery } from "../../services/api/wall";
import { closeOnboarding } from "../../services/reducer";

import ImgLogo from "../../assets/images/logo-colour.svg";
import ImgConnecting from "../../assets/images/home-connecting.svg";

import "./session-ended.scss";

function SessionEnded() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  return (
    <div
      id="SessionEnded"
      className={`${appState.isSessionEnded ? "open" : "close"}`}
    >
      <div className="logo">
        <img src={ImgLogo} />
      </div>
      <div className="text">
        <h2>Session Ended</h2>
        <p>Scan a new QR code to start again</p>
      </div>
      <div className="buttons">
        <input type="file" id="qr-input-file" accept="image/*" capture />
        <label className="btn-scan-qr" htmlFor="qr-input-file">
          SCAN QR CODE
        </label>
        {/* <Button className="btn-scan-qr">SCAN QR CODE</Button> */}
        <Button className="btn-error">Don’t see the QR code?</Button>
      </div>
    </div>
  );
}

export default SessionEnded;
