import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  IconButton,
  Input,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import {
  setListTitle,
  setCategories,
  setIsSearching,
} from "../../services/reducer";
import mainScrollTop from "../../services/util";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import "./search.scss";

export default function Search() {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { appState } = useSelector((state) => state);

  const handleChange = (prop) => (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    let searchResult = [];
    Object.keys(appState.pillars).map((pillarKey) => {
      appState.pillars[pillarKey].categories.map((category) => {
        if (category.title.toLowerCase().includes(searchValue.toLowerCase())) {
          searchResult.push(category);
        }
      });
    });

    return searchResult;
  };

  const handleClick = () => {
    dispatch(setListTitle("Search Result"));
    dispatch(setCategories(handleSearch()));
    dispatch(setIsSearching());
    mainScrollTop();
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleClick();
    }
  };

  return (
    <div id="Search">
      <FormControl variant="standard" className="search-bar">
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          value={searchValue}
          onChange={handleChange("password")}
          placeholder="Search"
          autoComplete={"off"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClick} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          onKeyPress={handleKeyPress}
        />
      </FormControl>
    </div>
  );
}
