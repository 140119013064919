import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";

import { setSelectedCategory, openPlayer } from "../../services/reducer";
import socket from "../../services/socket";

import { themeCategories } from "../../const";

import "./featured.scss";

export default function Featured() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const slickConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  const handleClick = (category) => {
    dispatch(setSelectedCategory(category));
    dispatch(openPlayer());

    socket.emit("setStory", {
      sessionKey: appState.socketSessionKey,
      storyKey: category._id,
    });
  };

  return (
    <div id="Featured">
      <h3>Featured</h3>
      <Slider {...slickConfig}>
        {appState.featuredCategories.map((category, index) => {
          const pillar = category.pillar || "Fundraiser";
          return (
            <div
              className="featured-item-wrapper"
              key={`featured-item-${index}`}
              onClick={() => handleClick(category)}
            >
              <div className="featured-item">
                <span>
                  <img src={themeCategories[pillar].background} alt={pillar} />{" "}
                  {pillar}
                  story
                </span>
                <h4>{category.heading}</h4>
                <p>{category.subheading}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
