import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { closeHelp } from "../../services/reducer";

import "./help.scss";

export default function Help() {
  const [selectedHelp, setselectedHelp] = useState(-1);

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleSelect = (e, index) => {
    setselectedHelp(index);
  };

  const handleCloseHelp = () => {
    setselectedHelp(-1);
    dispatch(closeHelp());
  };

  const helpContent = [
    {
      title: "What can I do with this installation?",
      content:
        "To become a featured fundraiser, simply press the DONATE button from your phone and select a value above $3,000,000",
    },
    {
      title: "How do I use my phone as a remote control of celestial wall?",
      content:
        "To become a featured fundraiser, simply press the DONATE button from your phone and select a value above $3,000,000",
    },
    {
      title: "Why nothing is playing on my phone?",
      content:
        "To become a featured fundraiser, simply press the DONATE button from your phone and select a value above $3,000,000",
    },
    {
      title: "How can I be featured in the wall?",
      content:
        "To become a featured fundraiser, simply press the DONATE button from your phone and select a value above $3,000,000",
    },
    {
      title: "How do I search for my name?",
      content:
        "To become a featured fundraiser, simply press the DONATE button from your phone and select a value above $3,000,000",
    },
  ];

  return (
    <div id="Help" className={`${appState.isHelpOpen ? "open" : "close"}`}>
      <div className="help-main">
        <div className="header">
          <h2>Help</h2>
          <IconButton onClick={handleCloseHelp}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="list">
          {helpContent.map((item, index) => {
            return (
              <div
                className={`list-item ${selectedHelp === index ? "open" : ""}`}
                onClick={(e) => handleSelect(e, index)}
                key={`HelpItem-${index}`}
              >
                <div className="item-header">
                  <div className="title">{item.title}</div>
                  <ArrowForwardIosIcon className="icon" />
                </div>
                <div className="content">{item.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
