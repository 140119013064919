import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { themeCategories } from "../../const";

import {
  setCategories,
  setIsSearching,
  setListTitle,
} from "../../services/reducer";
import mainScrollTop from "../../services/util";

import "./pillars.scss";

export default function Pillars() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const renderPillar = (pillar, subtext) => {
    const handleClick = () => {
      dispatch(setListTitle(pillar.heading));
      dispatch(setCategories(pillar.categories));
      dispatch(setIsSearching());
      mainScrollTop();
    };
    return (
      <div className={`pillar ${pillar.heading.toLowerCase()}`}>
        <div className="wrapper" onClick={handleClick}>
          <img src={themeCategories[pillar.heading].icon} />
          <h4>{pillar.heading}</h4>
          <p>{subtext}</p>
        </div>
      </div>
    );
  };

  return (
    <div id="Pillars">
      {renderPillar(appState.pillars.Donor, "See the stories")}
      {renderPillar(appState.pillars.Patient, "See the stories")}
      {renderPillar(appState.pillars.Fundraiser, "Know the insights")}
      {renderPillar(appState.pillars.Research, "Learn more")}
    </div>
  );
}
