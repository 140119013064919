import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setCategories,
  unsetIsSearching,
  setSelectedCategory,
  openPlayer,
} from "../../services/reducer";
import socket from "../../services/socket";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

import DonateBanner from "../DonateBanner";

import "./list.scss";

export default function List() {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const handleBack = () => {
    dispatch(unsetIsSearching());
  };

  const handleClick = (category) => {
    dispatch(setSelectedCategory(category));
    dispatch(openPlayer());
    socket.emit("setStory", {
      sessionKey: appState.socketSessionKey,
      storyKey: category._id,
    });
  };

  return (
    <div id="List">
      <div className="list-header">
        <IconButton className="btn-back" onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <h3>{appState.listTitle}</h3>
      </div>
      <div className="list-main">
        {appState.categories.map((item, index) => {
          return (
            <div
              className="item"
              key={`ListItem-${index}`}
              onClick={() => handleClick(item)}
            >
              <div
                className="img"
                style={{ backgroundImage: `url(${item.media})` }}
              />
              <h4>{item.title}</h4>
            </div>
          );
        })}
        {appState.categories.length === 0 && (
          <p className="no-result">There is no data</p>
        )}
      </div>
      <div className="divider" />
      <DonateBanner />
    </div>
  );
}
