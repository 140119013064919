import React from "react";

import { Button, Link } from "@mui/material";

import IconDonate from "../../assets/images/icon-donate-heart.svg";
import { DonationLink } from "../../const";

import "./banner.scss";

export default function DonateBanner() {
  return (
    <div id="DonateBanner">
      <p>
        Join the Peter Mac family
        <br />
        with a small donation
      </p>
      <Link href={DonationLink} target="_blank">
        <img src={IconDonate} /> DONATE NOW
      </Link>
    </div>
  );
}
