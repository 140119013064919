import React from "react";
import { useSelector } from "react-redux";

import Header from "../Header";
import Player from "../Player";
import Featured from "../Featured";
import Browse from "../Browse";
import Search from "../Search";
import Pillars from "../Pillars";
import List from "../List";
import Help from "../Help";
import DonateBanner from "../DonateBanner";
import Disconnect from "../Disconnect";

import Logo from "../../assets/images/logo-white.svg";

import "./main.scss";

function Main() {
  const { appState } = useSelector((state) => state);

  return (
    <div id="Main">
      <Header />
      <Player />
      <Help />
      <Disconnect />
      <div className={`main-body ${appState.isPlayerOpen ? "hide" : "show"}`}>
        {appState.isSearching ? (
          <List />
        ) : (
          <>
            <DonateBanner />
            <Featured />
            <Browse />
            <Search />
            <Pillars />
          </>
        )}
        <div className="logo">
          <img src={Logo} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(Main);
