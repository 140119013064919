import React from "react";
import { useSelector } from "react-redux";

import "./browse.scss";

export default function Browse() {
  const { appState } = useSelector((state) => state);

  return (
    <div id="Browse">
      <h3>
        Browse Stories <span>{appState.totalCategories}</span>
      </h3>
    </div>
  );
}
